import axios from 'axios'
import { AppLayout } from 'components/layouts'
import { useWalletManagement } from 'features/web3OnBoard'
import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Image, Text } from 'theme-ui'
import { fromWei, getMatchVestingContract, injectedProvider, MATCH_TOKEN_ADDR } from 'utils/match'

const titleStyle = { fontSize: '16px', lineHeight: '24px', color: 'rgba(255, 255, 255, 0.80)' }
const valueStyle = {
  mt: '2px',
  fontSize: '40px',
  fontWeight: 600,
  lineHeight: '100%',
  color: '#fff',
}
const subTextStyle = {
  mt: '4px',
  fontSize: '12px',
  fontWeight: 300,
  
  lineHeight: '100%',
  color: 'rgba(255, 255, 255, 0.50)',
}
function Rewards() {
  const { wallet, chainId } = useWalletManagement()
  const [contract, setContract] = useState<any>(null)
  const getContract = async () => setContract(await getMatchVestingContract())
  useEffect(() => {
    void getContract()
  }, [])

  const [info, setInfo] = useState<any>({})
  const getInfo = async () => {
    if (!wallet?.address || !contract) return
    const [userInfo, vested, tgeRateRes] = await Promise.all([
      contract.methods.vestings(wallet.address).call(),
      contract.methods.vestedAmount(wallet.address).call(),
      axios('/static/rewardsRatioList.json'),
    ])
    console.log(userInfo, vested, tgeRateRes)
    const tgeRate = (tgeRateRes.data as any).find((item: any) => item.address === wallet.address)
    setInfo({
      total: parseFloat(fromWei(userInfo.amount).toFixed(2)),
      claimable: parseFloat((fromWei(vested) - fromWei(userInfo.vestedAmount)).toFixed(2)),
      claimed: parseFloat(fromWei(userInfo.vestedAmount).toFixed(2)),
      tgeRate: tgeRate && tgeRate.tgeRate,
    })
  }
  useEffect(() => {
    void getInfo()
  }, [contract, wallet?.address])

  const [claiming, setClaiming] = useState(false)
  const [claimed, setClaimed] = useState(false)
  const toClaim = async () => {
    setClaiming(true)
    try {
      await contract.methods.claim().send({ from: wallet?.address })
      ;(window as any).onMessage('Claim (success)', 'success')
      setClaimed(true)
    } catch (e) {
      console.error(e)
      ;(window as any).onMessage('Claim (failed)', 'failed')
    }
    setClaiming(false)
  }

  const addMatchToken = () => {
    injectedProvider().request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: MATCH_TOKEN_ADDR,
          symbol: 'MATCH',
          decimals: 18,
        },
      },
    })
  }
  return (
    <>
      <Box
        sx={{
          width: '100%',
          pt: '254px',
          fontFamily: 'Poppins',
          '@media screen and (max-width: 600px)': {
            pt: '100px',
          },
        }}
      >
        <Text
          as="p"
          sx={{
            fontSize: '52px',
            fontWeight: 600,
            color: '#fff',
            lineHeight: '110%',

            textAlign: 'center',
          }}
        >
          Thank you for your support of
          <br /> Match Finance!
        </Text>
        <Text
          sx={{
            mt: '26px',
            fontSize: '36px',
            fontWeight: 500,
            lineHeight: '54px',
            color: '#F0F0F0',
            textAlign: 'center',
          }}
        >
          $MATCH
        </Text>
        <Flex sx={{ mt: '24px', justifyContent: 'center' }}>
          <Flex
            sx={{
              padding: '40px 80px 30px',
              background: 'linear-gradient(180deg, #1B1B44 0%, rgba(23, 22, 58, 0.00) 100%)',
              borderRadius: '16px',
              width: '1230px',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              '@media screen and (max-width: 600px)': {
                px: '40px',
              },
            }}
          >
            <Flex sx={{ alignItems: 'center', pb: '10px' }}>
              <Image src="/static/img/total_match.svg" />
              <Box ml="30px">
                <Text sx={titleStyle}>Total $MATCH</Text>
                <Text sx={valueStyle}>
                  {info.total !== undefined ? info.total.toLocaleString('en-US') : '--'}
                </Text>
                <Text sx={subTextStyle}>$MATCH</Text>
              </Box>
            </Flex>
            <Flex sx={{ alignItems: 'center', pb: '10px' }}>
              <Image src="/static/img/vesting_term.svg" />
              <Box ml="30px">
                <Text sx={titleStyle}>Vesting Term</Text>
                <Text sx={valueStyle}>{info.tgeRate || '--'}% tge</Text>
                <Text sx={subTextStyle}>
                  {info.tgeRate ? 100 - info.tgeRate : '--'}% linear vest in one year
                </Text>
              </Box>
            </Flex>
            <Flex sx={{ alignItems: 'center', pb: '10px' }}>
              <Image src="/static/img/claimable.svg" />
              <Box ml="30px">
                <Text sx={titleStyle}>Claimable</Text>
                <Text sx={valueStyle}>
                  {info.claimable !== undefined ? info.claimable.toLocaleString('en-US') : '--'}
                </Text>
                <Text sx={subTextStyle}>$MATCH</Text>
              </Box>
            </Flex>
            <Flex sx={{ alignItems: 'center', pb: '10px' }}>
              <Image src="/static/img/total_match.svg" />
              <Box ml="30px">
                <Text sx={titleStyle}>claimed</Text>
                <Text sx={valueStyle}>
                  {info.claimed !== undefined ? info.claimed.toLocaleString('en-US') : '--'}
                </Text>
                <Text sx={subTextStyle}>$MATCH</Text>
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <Flex sx={{ justifyContent: 'center' }}>
          {claimed ? (
            <Button
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: '24px',
                width: '330px',
                background: '#5065E4',
              }}
              onClick={addMatchToken}
            >
              Add MATCH to wallet
              <Image src="/static/icons/icon_add.svg" ml="12px" />
              <Image src="/static/icons/metamask.svg" ml="12px" />
            </Button>
          ) : (
            <Button
              disabled={!info.claimable || claiming}
              // variant={variant}
              onClick={toClaim}
              sx={{ mt: '24px', width: '330px', background: '#5065E4' }}
            >
              {claiming ? 'Claiming' : 'Claim'}
            </Button>
          )}
        </Flex>
      </Box>
    </>
  )
}

Rewards.layout = AppLayout

export default Rewards
